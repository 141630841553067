import { UserRole } from "db"
import getAnalyticsContentGroups, {
  ContentGroupsObject,
} from "./getAnalyticsContentGroups"
import getAnalyticsUserGroups, {
  UserGroupsObject,
} from "./getAnalyticsUserGroups"

const blankAnalyticsObject = {
  contentType: "other",
  slug: "NOT_APPLICABLE",
  parentSlug: "",
  subject: "NOT_APPLICABLE",
  isPublicPage: false,
  isKidsPage: false,
  eventType: "",
  keywords: "",
  isLearningLineLesson: false,
  learningLine: "",
  learningLineSubject: "NOT_APPLICABLE",
  learningLineSlug: "NOT_APPLICABLE",
  contentIsSpecialEducation: false,
  ...getAnalyticsContentGroups([]),
  ...getAnalyticsUserGroups([]),
  userType: "UNKNOWN",
} as AnalyticsObject

// Create a type based on the keys of blankAnalyticsObject
export type AnalyticsObject = {
  contentType:
    | "slide"
    | "learningLineOverview"
    | "lessonDetails"
    | "learningLineDetails"
    | "teacherPage"
    | "categoriesOverview"
    | "category"
    | "theme"
    | "homePage"
    | "other"
  eventType: "search" | ""
  keywords: string
  slug: string
  subject: "MUSIC" | "DRAMA" | "DANCE" | "NOT_APPLICABLE"
  parentSlug: string
  isLearningLineLesson: boolean
  learningLine: string
  learningLineSubject: "MUSIC" | "DRAMA" | "DANCE" | "NOT_APPLICABLE"
  learningLineSlug: string
  userType: UserRole | "PUBLIC" | "UNKNOWN"
  isPublicPage: boolean
  isKidsPage: boolean
  contentIsSpecialEducation: boolean
} & ContentGroupsObject &
  UserGroupsObject

// Ensure that analyticsValues can only have keys from AnalyticsObject
export default function generateAnalyticsObject(
  analyticsValues: Partial<AnalyticsObject> | undefined
) {
  return {
    ...blankAnalyticsObject,
    ...analyticsValues, // spread the new values into the object
  }
}
