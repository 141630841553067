import { Routes } from "@blitzjs/next"
import styled from "@emotion/styled"
import * as Accordion from "@radix-ui/react-accordion"
import { TbBrandFacebook } from "@react-icons/all-files/tb/TbBrandFacebook"
import { TbBrandInstagram } from "@react-icons/all-files/tb/TbBrandInstagram"
import { TbBrandYoutube } from "@react-icons/all-files/tb/TbBrandYoutube"
import { TbChevronDown } from "@react-icons/all-files/tb/TbChevronDown"
import { TbMessages } from "@react-icons/all-files/tb/TbMessages"
import { TbPlug } from "@react-icons/all-files/tb/TbPlug"
import { TbSend } from "@react-icons/all-files/tb/TbSend"
import { Value } from "@stringtale/react"
import { SUPPORT_LINKS } from "app/core/consts"
import Image from "next/image"
import { CONTAINER_WIDTH, DARK_BLUE_COLOR, PX14, PX20 } from "theme/consts"
import Group from "ui/Group"
import LinkBase from "ui/Link"
import Stack from "ui/Stack"
import Text from "ui/Text"
import Title from "ui/Title"

const Root = styled.div`
  color: white;
  width: 100%;
  font-size: ${PX14};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
`

const Inner = styled.div`
  width: 100%;
  max-width: calc(${CONTAINER_WIDTH});
`

const Link = styled(LinkBase)`
  &:hover {
    text-decoration: underline;
  }
`

const Content = styled.div`
  width: 100%;
  padding: 40px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SupportContent = styled(Content)`
  width: 100%;
  background-color: #4f4d84;
`

const SupportContentInner = styled(Inner)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  margin: 0 25px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }

  ${Title} {
    margin-right: 48px;
  }
`

const ColumnContent = styled(Content)`
  width: 100%;
  background-color: white;

  @media screen and (max-width: 1024px) {
    padding: 25px;
  }

  @media screen and (max-width: 640px) {
    padding: 0 12px;
  }
`

const ColumnContentInner = styled(Inner)`
  display: grid;
  gap: 32px;
  margin: 0 25px;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const ColumnContentTabletInner = styled(Inner)`
  grid-template-columns: 1fr;
  display: none;

  @media screen and (max-width: 1024px) {
    display: grid;
  }
`

const Column = styled.div`
  width: 100%;
  color: ${DARK_BLUE_COLOR};
`

const Separator = styled.div`
  height: 1px;
  width: 100%;
  max-width: ${CONTAINER_WIDTH};
  background-color: #ccccdb;
  box-sizing: border-box;
`

const BottomContent = styled(Content)`
  width: 100%;
  color: #4f4d84;
`

const BottomInner = styled(Inner)`
  display: flex;
  justify-content: space-between;
  margin: 0 25px;

  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
`

const TabletLink = styled.div`
  height: 44px;
  border-top: 1px solid #ccccdb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
`

const SupportSection = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 48px auto;
  @media screen and (min-width: 1025px) {
    transform: translateX(-56px);
  }
`

const BottomLogoText = styled.div`
  gap: 24px;
  align-items: center;
  display: flex;

  flex-direction: row;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

const MobileSocials = styled(Stack)`
  padding: 40px 0;
  width: 100%;
  display: none;
  @media screen and (max-width: 1024px) {
    display: flex;
  }
`
const DesktopSocials = styled(Group)`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const AccordionRoot = styled(Accordion.Root)`
  border-radius: 6px;
`

const AccordionContent = styled(Accordion.Content)`
  overflow: hidden;
  margin-bottom: 16px;

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }

  &[data-state="open"] {
    animation: slideDown 300ms ease-out;
  }
  &[data-state="closed"] {
    animation: slideUp 300ms ease-out;
  }
`

const AccordionItem = styled(Accordion.Item)`
  overflow: hidden;
  margin-top: 1px;

  &:first-child {
    margin-top: 0;
    border-bottom-left-radius: 4;
    border-bottom-right-radius: 4;
  }

  &:last-child {
    border-bottom-left-radius: 4;
    border-bottom-right-radius: 4;
  }

  &:focus-within {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 0 2px black;
  }
`

const Icon = styled.div`
  transition: transform 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AccordionTrigger = styled(Accordion.Trigger)`
  [data-state="open"] > & {
    ${Icon} {
      transform: rotate(180deg);
    }
    ${TabletLink} {
      font-weight: 700;
    }
  }
`

const isExternalLink = (link: string) => /^(https?:\/\/|\/\/)/.test(link)

const data: {
  title: JSX.Element
  links: { label: JSX.Element; url: string }[]
}[] = [
  {
    title: (
      <Value name="apps.web.src.footer.components.footer.scholing_training">
        Scholing & training
      </Value>
    ),
    links: [
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.compleet_scholingsaanbod">
            Compleet scholingsaanbod
          </Value>
        ),
        url: "https://123zing.nl/scholing/",
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.teamtrainingen">
            Teamtrainingen
          </Value>
        ),
        url: "https://123zing.nl/teamtrainingen/",
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.training_op_maat">
            Training op maat
          </Value>
        ),
        url: "https://123zing.nl/training-op-maat/",
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.online_trainingen_webinars">
            Online trainingen & webinars
          </Value>
        ),
        url: "https://123zing.nl/online-trainingen/",
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.agenda">
            Agenda
          </Value>
        ),
        url: "https://123zing.nl/agenda/",
      },
    ],
  },
  {
    title: (
      <Value name="apps.web.src.footer.components.footer.hulp_aan_de_slag">
        Hulp & Aan de slag
      </Value>
    ),
    links: [
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.starten_met_123zing">
            Starten met 123ZING
          </Value>
        ),
        url: "https://123zing.my.site.com/knowledgebase/s/topic/0TO9L00000000xfWAA/starten-met-123zing",
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.account_beheer">
            Account & beheer
          </Value>
        ),
        url: "https://123zing.my.site.com/knowledgebase/s/topic/0TO9L00000001ScWAI/account-beheer",
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.de_leeromgeving">
            De leeromgeving
          </Value>
        ),
        url: "https://123zing.my.site.com/knowledgebase/s/sub-leeromgeving",
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.jouw_rol_binnen_123zing">
            Jouw rol binnen 123ZING
          </Value>
        ),
        url: "https://123zing.my.site.com/knowledgebase/s/sub-jouw-rol-binnen-123zing",
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.muziek">
            Muziek
          </Value>
        ),
        url: "https://123zing.my.site.com/knowledgebase/s/sub-muziek",
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.dans">Dans</Value>
        ),
        url: "https://123zing.my.site.com/knowledgebase/s/topic/0TO9L00000000xzWAA/dans",
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.drama">
            Drama
          </Value>
        ),
        url: "https://123zing.my.site.com/knowledgebase/s/topic/0TO9L00000000y4WAA/drama",
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.speciaal_onderwijs">
            Speciaal onderwijs
          </Value>
        ),
        url: "https://123zing.my.site.com/knowledgebase/s/topic/0TO9L00000000y9WAA/speciaal-onderwijs",
      },
    ],
  },
  {
    title: (
      <Value name="apps.web.src.footer.components.footer.lesmateriaal">
        Lesmateriaal
      </Value>
    ),
    links: [
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.leerlijn_muziek">
            Leerlijn muziek
          </Value>
        ),
        url: Routes.LearningLineIndexOverview({
          vak: "muziek",
        }).href,
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.leerlijn_dans">
            Leerlijn dans
          </Value>
        ),
        url: Routes.LearningLineIndexOverview({
          vak: "dans",
        }).href,
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.leerlijn_drama">
            Leerlijn drama
          </Value>
        ),
        url: Routes.LearningLineIndexOverview({
          vak: "drama",
        }).href,
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.categorie_n">
            Categorieën
          </Value>
        ),
        url: Routes.CategoriesPage().href,
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.studio">
            Studio
          </Value>
        ),
        url: Routes.StudioPage().href,
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.kidspagina">
            Kidspagina
          </Value>
        ),
        url: Routes.LeerlingcodesPage().href,
      },
    ],
  },
  {
    title: (
      <Value name="apps.web.src.footer.components.footer.voorwaarden">
        Voorwaarden
      </Value>
    ),
    links: [
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.algemene_voorwaarden">
            Algemene voorwaarden
          </Value>
        ),
        url: "https://123zing.nl/algemene-voorwaarden/",
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.privacy_verklaring">
            Privacyverklaring
          </Value>
        ),
        url: "https://123zing.nl/privacyverklaring/",
      },
      {
        label: (
          <Value name="apps.web.src.footer.components.footer.disclaimer_songbox">
            Disclaimer Songbox
          </Value>
        ),
        url: "https://123zing.my.site.com/knowledgebase/s/article/disclaimer-songbox-youtube-avg",
      },
    ],
  },
]

const SocialLinks = () => {
  return (
    <>
      <Link
        href="https://www.youtube.com/channel/UCL-QIaMwz-ihBAPq15ijrkw"
        target="_blank"
        rel="noreferrer"
      >
        <TbBrandYoutube size={28} color="#424242" />
      </Link>
      <Link
        href="https://www.instagram.com/123_zing/"
        target="_blank"
        rel="noreferrer"
      >
        <TbBrandInstagram size={28} color="#424242" />
      </Link>
      <Link
        href="https://www.facebook.com/123zing.nl"
        target="_blank"
        rel="noreferrer"
      >
        <TbBrandFacebook size={28} color="#424242" />
      </Link>
    </>
  )
}

const Footer = () => {
  return (
    <Root>
      <SupportContent>
        <SupportContentInner>
          <Title size={PX20}>
            <Value name="apps.web.src.footer.components.footer.support_en_contact">
              Support & Contact
            </Value>
          </Title>
          <SupportSection>
            <TbMessages size={48} />
            <Stack>
              <Text size="15px" bold>
                <Value
                  name="apps.web.src.footer.components.footer.neem_gerust_contact"
                  version="1"
                >
                  Hulp & Aan de slag
                </Value>
              </Text>
              <Text>
                <Value
                  name="apps.web.src.footer.components.footer.hulp_en_aan_de_slag_zoek_antwoorden"
                  format={{
                    br: () => <br />,
                    link: (c) => (
                      <Link
                        underline
                        bold
                        href={SUPPORT_LINKS["GENERAL_SUPPORT"]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {c}
                      </Link>
                    ),
                  }}
                  version="1"
                >
                  {
                    /* HTML */ `Zoek antwoorden op je vragen en tips in <link>Hulp & Aan de slag</link>.`
                  }
                </Value>
              </Text>
            </Stack>
          </SupportSection>
          <SupportSection>
            <TbSend size={48} />
            <Stack>
              <Text size="15px" bold>
                <Value name="apps.web.src.footer.components.footer.contact_opnemen">
                  Contact opnemen
                </Value>
              </Text>
              <Text>
                <Value
                  name="apps.web.src.footer.components.footer.we_helpen_je_graag"
                  format={{
                    br: () => <br />,
                    link: (c) => (
                      <Link
                        underline
                        bold
                        href="https://123zing.nl/contact/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {c}
                      </Link>
                    ),
                  }}
                >
                  {`We helpen je graag! Neem contact op met onze <link>klantenservice</link>.`}
                </Value>
              </Text>
            </Stack>
          </SupportSection>
          <SupportSection>
            <TbPlug size={48} />
            <Stack>
              <Text size="15px" bold>
                <Value
                  name="apps.web.src.footer.components.footer.technisch_probleem"
                  version="1"
                >
                  {`Probleem met video's`}
                </Value>
              </Text>
              <Text>
                <Value
                  name="apps.web.src.footer.components.footer.functioneert_de_leeromgeving_niet"
                  format={{
                    br: () => <br />,
                    link: (c) => (
                      <Link
                        underline
                        bold
                        href="https://123zing.nl/support-videos"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {c}
                      </Link>
                    ),
                  }}
                  version="1"
                >
                  {`Spelen video's niet (goed) af in de leeromgeving? Doorloop het <link>stappenplan</link>.`}
                </Value>
              </Text>
            </Stack>
          </SupportSection>
        </SupportContentInner>
      </SupportContent>
      <ColumnContent>
        <ColumnContentInner>
          {data.map((column) => (
            <Column>
              <Stack gap={"8px"}>
                <Title size={PX14}>{column.title}</Title>
                {column.links.map((link) => (
                  <Link
                    href={link.url}
                    target={isExternalLink(link.url) ? "_blank" : undefined}
                    rel={isExternalLink(link.url) ? "noreferrer" : undefined}
                  >
                    {link.label}
                  </Link>
                ))}
              </Stack>
            </Column>
          ))}
        </ColumnContentInner>
        <ColumnContentTabletInner>
          {data.map((column, index) => (
            <AccordionRoot type="multiple">
              <AccordionItem value={index.toString()}>
                <Accordion.Header>
                  <AccordionTrigger asChild>
                    <Column>
                      <Stack gap={"8px"}>
                        <TabletLink>
                          <Text size={"15px"}>{column.title}</Text>
                          <Icon>
                            <TbChevronDown size={20} />
                          </Icon>
                        </TabletLink>
                      </Stack>
                    </Column>
                  </AccordionTrigger>
                </Accordion.Header>
                <AccordionContent>
                  <Stack gap="16px">
                    {column.links.map((link) => (
                      <Link href={link.url} target="_blank" rel="noreferrer">
                        <Text color={DARK_BLUE_COLOR}>{link.label}</Text>
                      </Link>
                    ))}
                  </Stack>
                </AccordionContent>
              </AccordionItem>
            </AccordionRoot>
          ))}
          <Separator />
        </ColumnContentTabletInner>
      </ColumnContent>
      <MobileSocials gap="16px" align="center" justify="center">
        <Title size={PX14} weight={"700"} style={{ color: DARK_BLUE_COLOR }}>
          <Value name="">Wil je ons volgen?</Value>
        </Title>
        <Group gap="24px" align="center" justify="center">
          <Link
            href="https://www.youtube.com/channel/UCL-QIaMwz-ihBAPq15ijrkw"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={"/icons/social/ico_yt.svg"}
              width={32}
              height={32}
              alt="Youtube"
            />
          </Link>
          <Link
            href="https://www.instagram.com/123_zing/"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={"/icons/social/ico_ig.svg"}
              width={28}
              height={28}
              alt="Youtube"
            />
          </Link>
          <Link
            href="https://www.facebook.com/123zing.nl"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={"/icons/social/ico_fb.svg"}
              width={28}
              height={28}
              alt="Youtube"
            />
          </Link>
        </Group>
      </MobileSocials>
      <Separator />
      <BottomContent>
        <BottomInner>
          <BottomLogoText>
            <Image
              src={"/123Zing_logo.svg"}
              width={72}
              height={55}
              alt="Logo"
            />
            <Text>
              <Value
                name="apps.web.src.footer.components.footer.de_methode_voor_muziek_dans_en_drama"
                version="1"
              >
                De methode voor muziek, dans en drama vol energie!
              </Value>
            </Text>
          </BottomLogoText>
          <DesktopSocials gap="24px" align="center">
            <Link
              href="https://www.youtube.com/channel/UCL-QIaMwz-ihBAPq15ijrkw"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src={"/icons/social/ico_yt.svg"}
                width={32}
                height={32}
                alt="Youtube"
              />
            </Link>
            <Link
              href="https://www.instagram.com/123_zing/"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src={"/icons/social/ico_ig.svg"}
                width={28}
                height={28}
                alt="Youtube"
              />
            </Link>
            <Link
              href="https://www.facebook.com/123zing.nl"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src={"/icons/social/ico_fb.svg"}
                width={28}
                height={28}
                alt="Youtube"
              />
            </Link>
          </DesktopSocials>
        </BottomInner>
      </BottomContent>
    </Root>
  )
}

export default Footer
